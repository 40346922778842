<template>
  <div :class="`flex-grow column flex flex-col ${last ? 'rounded-r-lg' : ''}`">
    <p class="border-b border-gray-300 flex justify-center items-center p-1">
      {{ columnLetter(column.index) }}
      <button
        class="px-2 py-1 flex justify-center items-center text-base font-medium leading-6 text-center text-white transition bg-red-600 rounded shadow ripple hover:shadow-lg hover:bg-red-700 focus:outline-none ml-2"
        @click="() => deleteColumn(drawer.index, column.index)"
        :data-testid="`delete-column-${drawer.index}-${column.index}`">
        <font-awesome-icon icon="trash-alt" size="xs" />
      </button>
    </p>
    <div class="flex flex-col spaces justify-between items-between flex-grow border-gray-300 border">
      <div class="flex-grow flex-col flex">
        <div v-for="(item, ind) in column.spaces" :key="ind" class="border-gray-400 flex-grow flex flex-col"
          :style="`flex:${item.size}`">
          <Space :item="item" :column="column" :drawer="drawer" />
        </div>
      </div>
      <div class="text-center flex text-base justify-center items-center mt-1 cursor-pointer">
        <font-awesome-icon icon="plus" size="xs" class="cursor-pointer mr-1 mb-0.5"
          @click="() => addSpace(drawer.index, column.index, enabledSizeInt)" />
        <span class="mr-2" @click="() => addSpace(drawer.index, column.index, enabledSizeInt)"
          :data-testid="`new-space-${drawer.index}-${column.index}`">Νέο συρτάρι</span>
        (<span class="cursor-pointer mr-1" @click="() => selectSize('S', 1)" v-html="activeSizeCheck('S')"
          :data-testid="`space-${drawer.index}-${column.index}-size-selection-s`"></span><span class="cursor-pointer mr-1"
          @click="() => selectSize('M', 2)" v-html="activeSizeCheck('M')"
          :data-testid="`space-${drawer.index}-${column.index}-size-selection-m`"></span><span class="cursor-pointer"
          @click="() => selectSize('L', 4)" v-html="activeSizeCheck('L')"
          :data-testid="`space-${drawer.index}-${column.index}-size-selection-l`"></span>)
      </div>
    </div>
  </div>
</template>

<script>
import Space from "./Space.vue";
import { letterForNumber } from "./../assets/general";

export default {
  components: { Space },
  name: "Column",
  data: () => {
    return { enabledSize: "S", enabledSizeInt: 1 };
  },
  props: ["first", "last", "drawer", "column"],
  methods: {
    addSpace(drawerIndex, columnIndex, size) {
      this.$store.dispatch("addSpace", { drawerIndex, columnIndex, size });
    },
    deleteColumn(drawerIndex, columnIndex) {
      this.$store.dispatch("deleteColumn", { drawerIndex, columnIndex });
    },
    activeSizeCheck(size) {
      if (this.enabledSize === size) return `<b>${size}</b>`;
      else return size;
    },
    selectSize(size, sizeInt) {
      this.enabledSize = size;
      this.enabledSizeInt = sizeInt;
    },
    columnLetter(index) {
      if (this.drawer.index > 1)
        return `${this.drawer.index}.${letterForNumber(index)}`;
      else return letterForNumber(index)
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  max-width: 33%;
}

.spaces {
  min-height: 600px;
}
</style>
