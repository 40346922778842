<template>
  <label class="text-left block m-2 mt-1 flex justify-center items-center">
    <span class="mr-2 leading-10 text-base">{{ uncheckedText }}</span>
    <input
      class="
        relative
        w-10
        h-5
        pt-1
        transition-all
        duration-200
        ease-in-out
        bg-gray-400
        rounded-full
        shadow-inner
        outline-none
        appearance-none
      "
      type="checkbox"
      :checked="checked"
    />
    <span class="ml-2 leading-10 text-base">{{ text }}</span>
  </label>
</template>

<script>
export default {
  name: "Toggle",
  props: ["text", "checked", "uncheckedText"],
  data: () => {
    return {};
  },
  methods: {
    inputClick(e) {
      e.preventDefault();
      // console.log(e);
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  &:before {
    content: "";
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    top: 0;
    left: 0;
    transform: scale(1.1);
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.2);
    background-color: white;
    transition: 0.2s ease-in-out;
  }

  &:checked {
    background-color: #7f9cf5;
  }

  &:checked:before {
    left: 1.25rem;
  }
}
</style>
