export const barcodePrefix = "~"; // ~

export const barcodeSuffix = "Enter";

export const barcodeRegex = /([^-]*)-([^-A-Za-z]+)/;

export const barcodeRegexWithLineItemId = /([^-]*)-([^-]*)-([^-A-Za-z]+)/;

export const orderIdLengthLimit = 9;

export const port = window.location.hostname.includes("uk.gr")
  ? 8889 // dror.uk.gr
  : window.location.hostname.includes("dror-stolidia")
    ? 7979 //dror-stolidia.myikona.gr
    : window.location.hostname.includes("dror-canvas")
      ? 7980 // dror-canvas.myikona.gr
      : window.location.hostname.includes("dror-sublimation")
        ? 7981 // dror-sublimation.myikona.gr
        : window.location.hostname.includes("dror-frames")
          ? 7982 // dror-frames
          : window.location.hostname.includes("dror-invoicing")
            ? 7983 // dror-invoicing
            : window.location.hostname.includes("dror-photobook")
              ? 7984
              : 8888; //dror.myikona.gr

export const defaultDrawers = [
  {
    "index": 1,
    "columns": [
      {
        "index": 1,
        "spaces": [
          {
            "index": 1,
            "size": 1,
            "totalItems": 0,
            "items": 0,
            "matching": false,
            "badge": "-"
          }
        ]
      },
      {
        "index": 2,
        "spaces": [
          {
            "index": 1,
            "size": 1,
            "totalItems": 0,
            "items": 0,
            "matching": false,
            "badge": "-"
          }
        ]
      },
      {
        "index": 3,
        "spaces": [
          {
            "index": 1,
            "size": 1,
            "totalItems": 0,
            "items": 0,
            "matching": false,
            "badge": "-"
          }
        ]
      },
      {
        "index": 4,
        "spaces": [
          {
            "index": 1,
            "size": 1,
            "totalItems": 0,
            "items": 0,
            "matching": false,
            "badge": "-"
          },
          {
            "index": 2,
            "size": 1,
            "totalItems": 0,
            "items": 0,
            "matching": false,
            "badge": "-"
          }
        ]
      }
    ]
  }
];
