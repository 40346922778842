import Vue from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faTrashAlt,
  faCheck,
  faTimes,
  faEraser,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
  faUndo,
  faUser,
  faSignOutAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { myStore } from "./assets/store";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getStoragePrefix } from "./assets/general";

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations as TracingIntegrations } from "@sentry/tracing";

if (window.location.hostname === "dror.myikona.gr") {
  const env = "production";

  Sentry.init({
    dsn: "https://0d416e4fd5324770b9a0faa30914d26a@o443673.ingest.us.sentry.io/5725475",
    environment: env,
    integrations: [
      new VueIntegration({ Vue, attachProps: true, logErrors: true }),
      // new TracingIntegrations.BrowserTracing(),
    ],
  });
}

require("dayjs/locale/el");

dayjs.extend(relativeTime);
dayjs.locale("el");

const store = myStore;

Vue.prototype.$dayjs = dayjs;

library.add(
  faPlus,
  faTrashAlt,
  faCheck,
  faTimes,
  faEraser,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
  faUndo,
  faUser,
  faSignOutAlt,
  faSave
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

const refreshAuthLogic = (failedRequest) => {
  const username = localStorage.getItem(`${getStoragePrefix()}/username`);
  let refreshToken = localStorage.getItem(`${getStoragePrefix()}/refreshToken`);

  if (username && refreshToken) {
    return axios
      .post("/token", { username, refreshToken })
      .then((tokenRefreshResponse) => {
        const token = tokenRefreshResponse.data.token;
        refreshToken = tokenRefreshResponse.data.refreshToken;

        if (token && refreshToken) {
          store.dispatch("setLoggedIn", { username, token, refreshToken });
          store.dispatch("toggleLoginModalOpen", false);
        }

        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + token;
        return Promise.resolve();
      })
      .catch(() => {
        // console.log(e);
        return Promise.reject();
      });
  } else {
    return Promise.reject();
  }
};

createAuthRefreshInterceptor(axios, refreshAuthLogic);

new Vue({
  render: (h) => h(App),
  store,
}).$mount("#app");
