<template>
  <div :class="`fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center ${false ? 'bg-red-600 bg-opacity-60' : 'bg-black bg-opacity-70'
    }  z-10`" @click.self="toggleOpen">
    <div class="flex flex-col justify-center items-center flex-grow" @click.self="toggleOpen">
      <!-- Information Modal -->
      <div class="md:w-1/3 sm:w-full rounded-lg shadow-lg bg-white my-3"
        :style="`${width ? 'width: ' + width + 'px' : ''}`">
        <div class="flex justify-between border-b border-gray-100 px-5 py-4">
          <div v-if="mode === 'info'" class="flex">
            <span class="text-blue-500 mr-2">
              <font-awesome-icon icon="info-circle" />
            </span>
            <span class="font-bold text-gray-700 text-lg">{{ title }}</span>
          </div>
          <div v-if="mode === 'warning'" class="flex">
            <span class="text-yellow-500 mr-2">
              <font-awesome-icon icon="exclamation-triangle" />
            </span>
            <span class="font-bold text-gray-700 text-lg">{{ title }}</span>
          </div>
          <div v-if="mode === 'error'" class="flex">
            <span class="text-red-500 mr-2">
              <font-awesome-icon icon="exclamation-triangle" />
            </span>
            <span class="font-bold text-gray-700 text-lg">{{ title }}</span>
          </div>
          <div>
            <button class="outline-none cursor-pointer focus:outline-none">
              <div class="fa fa-times-circle text-red-500 hover:text-red-600 transition duration-150 focus:outline-none"
                @click="toggleOpen">
                <font-awesome-icon icon="times-circle" />
              </div>
            </button>
          </div>
        </div>

        <div class="px-10 py-5 text-gray-600">
          <slot />
        </div>

        <div class="px-5 py-4 flex justify-end" v-if="mode === 'info'">
          <button class="text-sm py-2 px-3 text-gray-500 hover:text-gray-600 transition duration-150 focus:outline-none"
            @click="toggleOpen">
            Κλείσιμο
          </button>
        </div>
        <div v-else-if="mode === 'warning'">
          <div class="px-5 py-4 flex justify-end">
            <button
              class="bg-yellow-500 mr-1 rounded text-sm py-2 px-3 text-white hover:bg-yellow-600 transition duration-150 focus:outline-none"
              @click="toggleOpen">
              Ακύρωση
            </button>
            <button
              class="text-sm py-2 px-3 text-gray-500 hover:text-gray-600 transition focus:outline-none duration-150"
              @click="action">
              OK
            </button>
          </div>
        </div>
        <div v-else-if="mode === 'error'">
          <div class="px-5 py-4 flex justify-end">
            <button
              class="text-sm py-2 px-3 text-gray-500 hover:text-gray-600 transition duration-150 focus:outline-none"
              @click="() => toggleOpen(false)">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["mode", "title", "toggleOpen", "action", "width"],
  data: () => {
    return {
      isOpen: true,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.mode === "error") this.toggleOpen(false);
    }, 2500);
  },
};
</script>

<style lang="scss" scoped></style>
