<template>
  <div class="m-5">
    <p class="flex justify-center items-center">
      Συρταριέρα #{{ drawer.index }}
      <button class="
          inline-block
          px-3
          py-1
          text-base
          font-medium
          leading-6
          text-center text-white
          transition
          bg-blue-600
          rounded
          shadow
          ripple
          hover:shadow-lg
          hover:bg-blue-700
          focus:outline-none
          ml-2
        " @click="() => addColumn(drawer.index)" :data-testid="`new-column-${drawer.index}`">
        Νέα στήλη <font-awesome-icon icon="plus" /></button><button class="
          inline-block
          px-3
          py-1
          text-base
          font-medium
          leading-6
          text-center text-white
          transition
          bg-red-600
          rounded
          shadow
          ripple
          hover:shadow-lg
          hover:bg-red-700
          focus:outline-none
          ml-2
        " @click="() => deleteDrawer(drawer.index)" :data-testid="`delete-drawer-${drawer.index}`">
        Διαγραφή συρταριέρας <font-awesome-icon icon="trash-alt" />
      </button>
    </p>
    <div v-if="drawer.randomStackItems" class="random-stack-items mt-6">
      <div v-for="item in drawer.randomStackItems" :key="item.id" :class="`inline-block border-gray-400 border p-2 text-white item ${statusClass(
        item
      )}`" :data-order-id="item.id" :data-total-items="item.totalItems">
        <div class="font-bold">{{ item.id }}</div>
        <div class="text-sm" v-if="item.totalItems > 0">
          <span v-if="item.lineItems && item.lineItems.length > 0"
            class="relative mr-2 cursor-pointer info-tooltip-trigger">
            <font-awesome-icon icon="info-circle" size="lg" />
            <div class="info-tooltip">
              <b>Line item IDs: </b><br />
              {{ item.lineItems.join(', ') }}
            </div>
          </span>
          <b>C: {{ minutesTimeStarted(item) }}h</b>
          <div class="text-xs">LI: {{ minutesTimeModified(item) }}h</div>
        </div>
        <div class="text-base">({{ item.items }}/{{ item.totalItems }})</div>
        <div class="controls">
          <div class="m-1" v-if="item.matching">
            <font-awesome-icon icon="check" class="cursor-pointer text-green-600"
              @click.stop="() => addLineItemToSpace(false, item.id)" />
            <font-awesome-icon icon="times" class="cursor-pointer ml-2 text-red-600"
              @click.stop="() => addLineItemToSpace(true, item.id)" />
          </div>
          <div class="m-1" v-else>
            <font-awesome-icon icon="trash-alt" class="cursor-pointer text-white-600"
              @click.stop="() => deleteSpace(item.id)" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row p-2" v-if="drawer.columns">
      <Column v-for="column in drawer.columns" :key="column.index" :first="column.index === 1"
        :last="column.index === drawer.columns.length" :column="column" :drawer="drawer" />
      <div class="bg-gray-600 text-white" v-if="queue && queue.length > 0">
        <h3 class="pb-1 pt-1.5 px-2 border-b border-gray-400">
          Εκκρεμή
          <font-awesome-icon icon="trash-alt" class="ml-2 cursor-pointer" size="sm" :data-testid="`clear-queue`"
            @click="() => clearQueue()" />
        </h3>
        <div class="queue">
          <div v-for="item in queue" :key="item.id || item" :class="`
              border border-t-0 border-b-1 border-gray-400
              text-base
              leading-3
              p-2
              flex
              justify-between
              items-center
              ${itemDateClass(daysSinceCreation(item.date))}
            `">
            {{ item.id || item }}
            <font-awesome-icon icon="trash-alt" class="ml-2 cursor-pointer" size="sm"
              :data-testid="`delete-queue-item-${item.id || item}`" @click="() => deleteQueueItem(item)" />
          </div>
        </div>
      </div>
    </div>

    <div class="text-center text-base flex justify-center items-center">
      <div class="inline-block mr-2">
        <span class="legend-box mr-2 bg-blue-500"></span><i>Περιμένει επιβεβαίωση</i>
      </div>
      <div class="inline-block mr-2">
        <span class="legend-box mr-2 bg-green-500"></span><i>Ολοκληρωμένο</i>
      </div>
      <div class="inline-block mr-2">
        <span class="legend-box mr-2 bg-yellow-400"></span><i>Λιγότερο απο 1 μέρα</i>
        πριν
      </div>
      <div class="inline-block mr-2">
        <span class="legend-box mr-2 bg-yellow-600"></span><i>Παραπάνω απο 1 μέρα</i>
        πριν
      </div>
      <div class="inline-block mr-2">
        <span class="legend-box mr-2 bg-red-500"></span><i>Παραπάνω απο 3 μέρες</i>
        πριν
      </div>
      <div class="inline-block mr-2">
        <span class="legend-box mr-2 bg-gray-500"></span><i>Επιλεγμένο</i>
      </div>
    </div>
    <div class="text-base">
      <span class="mr-10"><b>C:</b> <i>Ξεκίνησε (Creation)</i></span><span> <b>LI:</b> <i>Τελευταία</i></span>
      αλλαγή (Last In)
    </div>
  </div>
</template>

<script>
import Column from "./Column.vue";

export default {
  name: "Drawer",
  props: ["drawer"],
  data: () => {
    return {};
  },
  computed: {
    queue() {
      return this.$store.state.queue;
    },
  },
  components: {
    Column,
  },
  methods: {
    daysSinceCreationItem(item) {
      const now = this.$dayjs(new Date());
      const started = this.$dayjs(item.startedDate);
      return now.diff(started, "day");
    },
    ratioOfItems(item) {
      if (item?.totalItems === 0) return 0;

      return item?.items / item?.totalItems;
    },
    isFull(item) {
      return this.ratioOfItems(item) === 1 && item?.totalItems > 0;
    },
    statusClass(item) {
      if (this.isFull(item)) return "bg-green-500 text-white";
      if (item.matching) return "bg-blue-500 text-white";

      if (this.daysSinceCreationItem(item) >= 3) return "bg-red-500 text-white";
      else if (this.daysSinceCreationItem(item) >= 1)
        return "bg-yellow-600 text-white";
      else if (this.daysSinceCreationItem(item) >= 0 && item.totalItems > 0)
        return "bg-yellow-400 text-white";
    },
    minutesTimeStarted(item) {
      const now = this.$dayjs(new Date());
      const started = this.$dayjs(item.startedDate);
      const hours = now.diff(started, "hour");

      return `${Math.trunc(hours / 24)}d ${hours % 24}`;
    },
    minutesTimeModified(item) {
      const now = this.$dayjs(new Date());
      const modified = this.$dayjs(item.modifiedDate);
      const hours = now.diff(modified, "hour");

      return `${Math.trunc(hours / 24)}d ${hours % 24}`;
    },
    addLineItemToSpace(abort = false, id) {
      const lineItemId = localStorage.getItem('lastLineItemId')

      this.$store.dispatch("addLineItemToSpace", {
        abort,
        id,
        lineItemId
      });
    },
    deleteSpace(id) {
      this.$store.dispatch("deleteSpace", {
        id,
      });
    },
    addColumn(drawerIndex) {
      this.$store.dispatch("addColumn", drawerIndex);
    },
    deleteDrawer(drawerIndex) {
      this.$store.dispatch("deleteDrawer", drawerIndex);
    },
    clearQueue() {
      let conf = confirm(
        `Θελετε σίγουρα να αδειάσετε την ουρά εκκρεμών παραγγελιών;`
      );
      if (conf) this.$store.dispatch("clearQueue");
    },
    deleteQueueItem(item) {
      const id = item?.id || item;
      let conf = confirm(
        `Θελετε σίγουρα να διαγράψετε το ${id} από την στοίβα των παραγγελιών που εκκρεμούν;`
      );
      if (conf) this.$store.dispatch("deleteItemFromQueue", { id });
    },
    daysSinceCreation(date) {
      if (!date) return "-";
      const now = this.$dayjs(new Date());
      const started = this.$dayjs(date);
      return now.diff(started, "day");
    },
    itemDateClass(daysSinceCreation) {
      if (daysSinceCreation >= 3) return "bg-red-500 text-white";
      else if (daysSinceCreation >= 1) return "bg-yellow-600 text-white";
      else if (daysSinceCreation >= 0) return "bg-yellow-400 text-white";
    },
  },
};
</script>

<style lang="scss" scoped>
.legend-box {
  min-height: 16px;
  min-width: 16px;
  display: inline-block;
}

.queue {
  max-height: 950px;
  overflow: auto;
}

.random-stack-items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  .controls {
    min-width: 100px;
  }
}

.info-tooltip {
  display: none;
  position: absolute;
  top: 25px;
  left: 0px;
  background: black;
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  min-width: 150px;
  z-index: 1;
}

.info-tooltip-trigger {
  &:hover {
    .info-tooltip {
      display: block;
    }
  }
}
</style>
