<template>
  <div id="app" class="relative">

    <div v-if="!loggedIn">
      <h2 class="text-red-600 text-3xl">ΧΩΡΙΣ ΣΥΝΔΕΣΗ! ΠΑΡΑΚΑΛΩ ΣΥΝΔΕΘΕΙΤΕ ΓΙΑ ΝΑ ΚΑΝΕΤΕ ΑΛΛΑΓΕΣ</h2>
    </div>

    <button class="
        inline-block
        px-6
        py-2
        mr-2
        text-base
        font-medium
        leading-6
        text-center text-white
        transition
        bg-green-600
        rounded
        shadow
        ripple
        hover:shadow-lg hover:bg-green-500
        focus:outline-none
        mt-3
        mb-3
      " data-testid="clear-all-completed" @click="clearAllCompleted">
      Άδειασμα ολοκληρωμένων <font-awesome-icon icon="eraser" /></button><button class="
        inline-block
        px-6
        py-2
        mr-2
        text-base
        font-medium
        leading-6
        text-center text-white
        transition
        bg-black
        rounded
        shadow
        ripple
        hover:shadow-lg hover:bg-gray-800
        focus:outline-none
        mt-3
        mb-3
      " @click="undo" v-if="isUndoVisible" data-testid="undo-button">
      UNDO <font-awesome-icon icon="undo" /></button>
    <input type="text" class="
        border-gray-600 border
        rounded-md
        outline-none
        focus:outline-none
        p-2
        text-base
      " ref="input" data-testid="my-input" oninput="this.value = this.value.replace(/[^0-9-]+/, '')" />
    <button class="
        inline-block
        px-6
        py-2
        ml-2
        text-base
        font-medium
        leading-6
        text-center text-white
        transition
        bg-blue-600
        rounded
        shadow
        ripple
        hover:shadow-lg hover:bg-blue-500
        focus:outline-none
        mt-3
        mb-3
      " @click="() => toggleLoginModalOpen(true)" data-testid="toggle-login-modal" v-if="!loggedIn">
      Σύνδεση <font-awesome-icon icon="user" />
    </button>
    <button class="
        inline-block
        px-6
        py-2
        ml-2
        mr-2
        text-base
        font-medium
        leading-6
        text-center text-white
        transition
        bg-blue-600
        rounded
        shadow
        ripple
        hover:shadow-lg hover:bg-blue-500
        focus:outline-none
        mt-3
        mb-3
      " @click="logout" data-testid="logout-button" v-else>
      Αποσύνδεση <font-awesome-icon icon="sign-out-alt" />
    </button>

    <button v-if="loggedIn" class="
        inline-block
        px-6
        py-2
        ml-2
        mr-2
        text-base
        font-medium
        leading-6
        text-center text-white
        transition
        bg-pink-500	
        rounded
        shadow
        ripple
        hover:shadow-lg hover:bg-pink-400
        focus:outline-none
        mt-3
        mb-3
      " data-testid="save-load-button" @click="toggleOpenSaveModal">
      SAVE / LOAD <font-awesome-icon icon="save" />
    </button>

    <Modal mode="info" title="LOAD / SAVE" :isOpen="loadSaveModalOpen" :toggleOpen="toggleOpenSaveModal" :width="800"
      v-if="loadSaveModalOpen" data-testid="login-save-modal">
      <div class="flex">
        <div class="w-6/12 border-r border-gray-400">
          <h4 class="mb-4 font-bold">SAVE</h4>

          <button class="bg-blue-500 hover:bg-blue-400 rounded-md text-white p-2 text-lg" @click="saveDataToFile">SAVE
            ALL DATA TO FILE</button>
        </div>
        <div class="w-6/12 flex justify-center items-center flex-col">
          <h4 class="mb-4 font-bold">LOAD</h4>

          <input type="file" placeholder="Select file with local storage data" class="w-64 mb-4 mt-7"
            @change="localStorageFileInputChange" accept=".txt" />

          <button class="bg-blue-500 hover:bg-blue-400 rounded-md text-white p-2 text-lg" @click="loadDataFromFile">LOAD
            SAVED DATA FROM
            FILE</button>
        </div>
      </div>
    </Modal>

    <div class="
        absolute
        top-0.5
        left-0.5
        text-9xl
        -mt-10
        text-white
        bg-green-600
        p-4
        pt-0
        pb-4
        rounded-md
      " v-if="lastSpace">
      {{ lastSpace }}
    </div>

    <div class="
        absolute
        top-0.5
        right-0.5
        text-2xl
        -mt-10
        text-white
        bg-green-300
        p-4
        pb-4
        rounded-md
      " v-if="itIsTodaysDate()">
      {{ dayJSDate(stats.date) }}
      <br />
      Line items added: {{ stats.lineItemsAdded }}
      <br />
      Orders completed: {{ stats.ordersCompleted }}
    </div>

    <Toggle text="Scroll στο σχετικο ραφι" uncheckedText="Χωρίς scroll" :checked="doScroll"
      @click.native="toggleScroll" />

    <Modal mode="info" title="Σύνδεση" :isOpen="loginModalOpen" :toggleOpen="toggleLoginModalOpen" v-if="loginModalOpen"
      data-testid="login-modal">
      <div class="text-center m-2">
        Username
        <input @keydown.enter="login" ref="username" class="
            border border-gray-700
            p-2
            rounded-md
            block
            mx-auto
            focus:outline-none
          " />
      </div>
      <div class="text-center m-2">
        Password
        <input @keydown.enter="login" ref="password" class="
            border border-gray-700
            p-2
            rounded-md
            block
            mx-auto
            focus:outline-none
          " />
      </div>
      <button class="
          inline-block
          px-6
          py-2
          mr-2
          text-base
          font-medium
          leading-6
          text-center text-white
          transition
          bg-blue-700
          rounded
          shadow
          ripple
          hover:shadow-lg hover:bg-blue-600
          focus:outline-none
          mt-3
          mb-3
        " @click="login">
        Σύνδεση
      </button>
    </Modal>

    <Modal mode="error" title="Σφάλμα" :isOpen="errorModalOpen" :toggleOpen="toggleErrorModalOpen"
      v-if="errorModalOpen">
      {{ errorText }}</Modal>

    <Drawer v-for="(drawer, index) in drawers" :key="index" :drawer="drawer" />
    <button class="
        inline-block
        px-6
        py-2
        text-base
        font-medium
        leading-6
        text-center text-white
        transition
        bg-blue-700
        rounded
        shadow
        ripple
        hover:shadow-lg hover:bg-blue-800
        focus:outline-none
        mt-3
        mb-3
      " @click="addDrawer" data-testid="add-drawer">
      Νέα συρταριέρα <font-awesome-icon icon="plus" />
    </button>
    <br />
    <div class="flex justify-center items-center mb-6">
      <div class="p-2">
        <label class="text-base">Νέα ετικέτα:
          <input placeholder="πχ. ΒΡΦΚ" class="
              border-gray-600 border
              rounded-md
              p-2
              focus:outline-none
              outline-none
              mr-2
            " v-model="newLabel" @input.stop @keydown.stop @keydown.enter="addLabel" /></label><button class="
            bg-purple-500
            rounded-md
            text-white
            p-2
            text-base
            outline-none
            focus:outline-none
          " @click="addLabel">
          OK
        </button>
      </div>

      <div class="p-2">
        <label class="text-base">Διαγραφή ετικέτας:
          <select v-model="labelToDelete" class="
              mr-2
              border-gray-600 border
              p-2
              rounded-md
              focus:outline-none
              outline-none
            ">
            <option value="--">--</option>
            <option v-for="label in labels" :key="label" :value="label">
              {{ label }}
            </option>
          </select></label><button class="
            bg-purple-500
            rounded-md
            text-white
            p-2
            text-base
            outline-none
            focus:outline-none
          " @click="deleteLabel">
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  barcodePrefix,
  barcodeRegex,
  barcodeRegexWithLineItemId,
  barcodeSuffix,
  orderIdLengthLimit,
  port,
} from "./assets/consts.js";
import {
  createFlash,
  numberForLetter,
  playError,
  letterForNumber,
  getStoragePrefix,
  getServerId,
  sleep,
  getOrigin,
} from "./assets/general.js";
import { getDrawerData, updateDrawerData } from "./assets/store.js";
import Drawer from "./components/Drawer.vue";
import Modal from "./components/Modal.vue";
import Toggle from "./components/Toggle.vue";

export default {
  name: "App",
  components: {
    Drawer,
    Modal,
    Toggle,
  },
  data: () => {
    return {
      lastSpace: "",
      previousStorage: null,
      doScroll: false,
      newLabel: "",
      labelToDelete: "--",
      loadSaveModalOpen: false,
      localStorageFile: null,
    };
  },
  computed: {
    isUndoVisible() {
      return (
        JSON.stringify(this.$store.state.previousStorage) !==
        localStorage.getItem(`${getStoragePrefix()}/drawers`)
      );
    },
    drawers() {
      return this.$store.state.drawers;
    },
    errorModalOpen() {
      return this.$store.state.errorModalOpen;
    },
    errorText() {
      return this.$store.state.errorText;
    },
    stats() {
      return this.$store.state.stats;
    },
    loginModalOpen() {
      return this.$store.state.loginModalOpen;
    },
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    labels() {
      return this.$store.state.labels;
    },
    isThereMatchingSpace() {
      let isThere = false;

      this.drawers.forEach((drawer) => {
        drawer.columns.forEach((column) => {
          column.spaces.forEach((space) => {
            if (space.matching) isThere = true;
          });
        });

        drawer?.randomStackItems?.forEach((item) => {
          if (item.matching) isThere = true;
        });
      });

      return isThere;
    },
  },
  methods: {
    localStorageFileInputChange(e) {
      const file = e.target.files?.[0]
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          // this will then display a text file
          this.localStorageFile = reader.result;
        },
        false,
      );

      if (file) {
        reader.readAsText(file);
      }
    },
    loadDataFromFile() {
      if (!this.localStorageFile) {
        alert('Παρακαλώ επιλέξτε ενα αρχείο!')
        return;
      }

      if (confirm('Ειστε βεβαιος/η οτι θέλετε να αντικαταστήσετε ολα τα τοπικα δεδομενα με τα δεδομενα απο το αρχειο?')) {
        const data = JSON.parse(this.localStorageFile);
        this.$store.commit("toggleUpdatingRn", { bln: true })
        // console.log(data)
        Object.keys(data)?.forEach((key) => {
          // console.log('key: ', key)
          const dataPiece = data[key]?.replaceAll(/\\/g, "");
          // if (key === 'local/drawers') console.log('data: ', JSON.parse(dataPiece))
          localStorage.setItem(key, dataPiece)
          // console.log(localStorage)
        })

        const state = this.$store.state

        state.previousStorage = state.drawers;

        state.drawers = JSON.parse(localStorage.getItem(`${getStoragePrefix()}/drawers`))
        state.stats = JSON.parse(localStorage.getItem(`${getStoragePrefix()}/stats`))

        updateDrawerData(state, 'loaddatafromfile');
        this.$store.commit("toggleUpdatingRn", { bln: false })
        this.toggleOpenSaveModal();

      }
    },
    saveDataToFile() {
      /* dump local storage to string */

      var a = {};
      for (var i = 0; i < localStorage.length; i++) {
        var k = localStorage.key(i);
        var v = localStorage.getItem(k);
        a[k] = v;
      }

      /* save as blob */

      var textToSave = JSON.stringify(a)
      var textToSaveAsBlob = new Blob([textToSave], {
        type: "text/plain"
      });
      var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);

      /* download without button hack */

      var downloadLink = document.createElement("a");
      downloadLink.download = 'dror-backup-' + window?.location?.hostname + '-' + new Date().toISOString().slice(0, 10) + '_' + new Date().toLocaleTimeString() + '.txt'
      downloadLink.innerHTML = "Download File";
      downloadLink.href = textToSaveAsURL;
      downloadLink.onclick = function () {
        document.body.removeChild(event.target);
      };
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();


    },
    toggleOpenSaveModal() {
      this.loadSaveModalOpen = !this.loadSaveModalOpen;
    },
    isThereMatchingSpaceInResponse(drawers) {
      if (drawers) {
        let isThere = false;

        drawers.forEach((drawer) => {
          drawer.columns.forEach((column) => {
            column.spaces.forEach((space) => {
              if (space.matching) isThere = true;
            });
          });
          drawer?.randomStackItems?.forEach((item) => {
            if (item.matching) isThere = true;
          });
        });

        return isThere;
      }
      return false;
    },
    addLabel() {
      this.$store.dispatch("addLabel", { label: this.newLabel });
      this.newLabel = "";
    },
    deleteLabel() {
      if (this.labelToDelete === "--") return;

      this.$store.dispatch("deleteLabel", { label: this.labelToDelete });
      this.labelToDelete = "--";
    },
    toggleScroll() {
      this.doScroll = !this.doScroll;
    },
    check() {
      const token = localStorage.getItem(`${getStoragePrefix()}/token`);
      const origin = getOrigin();

      return axios
        .get(
          `${origin?.replace(/:[0-9]+/, `:${port}`)}/check?id=${getServerId()}`,
          {
            timeout: 30000,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (response) => {
          let stopUpdates = this.isThereMatchingSpaceInResponse(
            response.data.drawers
          );

          if (!stopUpdates) {
            if (response.data.queue)
              this.$store.dispatch("updateQueue", {
                queue: response.data.queue,
              });

            if (
              response.data.drawers &&
              JSON.stringify(response.data.drawers) !==
              JSON.stringify(this.drawers)
            ) {
              // if (this.loggedIn)
              this.$store.dispatch("updateDrawers", { drawers: response.data.drawers, place: 'App.vue check if NOT stopupdates' });
            }
          } else {
            if (!this.isThereMatchingSpace)
              this.$store.dispatch("updateDrawers", { drawers: response.data.drawers, place: 'App.vue check if YES stopupdates' });


            // console.log("updates stopped!");
          }
          await this.check();
        })
        .catch(async () => {
          // console.log(e);
          await sleep(4000);
          await this.check();
        });
    },
    login() {
      const username = this.$refs.username?.value;
      const password = this.$refs.password?.value;

      axios
        .post("/login", {
          username,
          password,
        })
        .then((response) => {
          // console.log(response.data);
          const token = response.data.token;
          const refreshToken = response.data.refreshToken;

          if (token && refreshToken) {
            this.setLoggedIn({ username, token, refreshToken });
            this.toggleLoginModalOpen(false);
            getDrawerData(this.$store.state);
          }
        })
        .catch(() => {
          // console.log(e);
          this.$store.commit("changeErrorText", "Η σύνδεση απέτυχε!");
          this.toggleErrorModalOpen();
        });
    },
    setLoggedIn({ username, token, refreshToken }) {
      this.$store.dispatch("setLoggedIn", { username, token, refreshToken });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    clearAllCompleted() {
      this.$store.dispatch("clearAllCompleted");
    },
    toggleErrorModalOpen(bool) {
      this.$store.dispatch("toggleErrorModalOpen", bool);
    },
    toggleLoginModalOpen(bool) {
      this.$store.dispatch("toggleLoginModalOpen", bool);
      this.$nextTick(() => this.$refs?.username?.focus());
    },
    undo() {
      this.$store.dispatch("undo");
    },
    letterFromNumber(index) {
      return letterForNumber(index);
    },
    myFindIndex(collection, index) {
      return collection.findIndex((drawer) => drawer.index == index);
    },
    addDrawer() {
      this.$store.dispatch("addDrawer");
    },
    emptySpace(drawerIndex, columnIndex, spaceIndex) {
      this.$store.dispatch("emptySpace", {
        drawerIndex,
        columnIndex,
        spaceIndex,
      });
    },
    markAsMatching(data) {
      this.$store.dispatch("markAsMatching", data);
    },
    submit(val) {
      console.log("submit: ", val);

      let newBarcode = true;
      let matches = val.match(barcodeRegexWithLineItemId); // backwards compatibility for old barcodes
      if (!matches) {
        matches = val.match(barcodeRegex);
        if (matches) newBarcode = false;
      }
      // console.log(matches);
      if (
        (matches && matches.length > 2 && !newBarcode) ||
        (newBarcode && matches && matches.length > 3)
      ) {
        // console.log("order No: ", matches[1]);
        // console.log("totalItems :", matches[2]);
        matches[1] = matches[1]?.toLowerCase(); // match to lowercase
        const orderId = matches[1]?.toLowerCase();
        const lineItemId = newBarcode ? matches[2] : null;
        const numberOfLineItems = newBarcode ? matches[3] : matches[2]
        if (lineItemId) localStorage.setItem('lastLineItemId', lineItemId)
        console.log('orderId: ', orderId, ' lineItemId: ', lineItemId, ' numberOfLineItems: ', numberOfLineItems)

        if (orderId?.length > orderIdLengthLimit) {
          playError();
          setTimeout(() => {
            this.$store.commit(
              "changeErrorText",
              `Το order ID δεν ειναι εγκυρο. Το ${orderId} ειναι μεγαλύτερο σε μήκος απο το όριο (${orderIdLengthLimit} χαρακτηρες), 
              θα γινει εκκαθαριση του input`
            );
            createFlash("red");
            this.toggleErrorModalOpen();
            if (this.$refs.input) this.$refs.input.value = '';
          }, 300);
          return;
        }

        const foundSpace = this.findSpaceByOrderId(orderId);
        if (foundSpace) {
          if (foundSpace.dataset.totalItems !== numberOfLineItems) {
            playError();
            setTimeout(() => {
              this.$store.commit(
                "changeErrorText",
                `Βρεθηκε η παραγγελία αλλά ο αριθμός αντικειμένων είναι λάθος! Είναι ${numberOfLineItems} ενώ θα έπρεπε να είναι ${foundSpace.dataset.totalItems}`
              );
              createFlash("red");
              this.toggleErrorModalOpen();
            }, 300);
            return;
          }

          // console.log(foundSpace);
          const ele = document.querySelector(`[data-order-id="${orderId}"]`);
          if (ele && this.doScroll) {
            ele.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          const drawerIndex = foundSpace.dataset.drawerIndex;
          const columnIndex = foundSpace.dataset.columnIndex;
          const spaceIndex = foundSpace.dataset.spaceIndex;

          if (!drawerIndex || !columnIndex || !spaceIndex) {
            const id = orderId;
            const randomStackSpace = this.drawers?.[0]?.randomStackItems.find(
              (it) => it.id === id
            );
            if (randomStackSpace && randomStackSpace.matching)
              this.addLineItemToRandomStack(false, id);
            else if (randomStackSpace && !randomStackSpace.matching) {
              // console.log("marking as matching εχιστινγ");
              this.markAsMatching({
                drawerIndex: 1,
                id,
                totalItems: numberOfLineItems,
              });
              this.lastSpace = `Στ.`;
            }
            return;
          }

          if (
            this.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
              spaceIndex - 1
            ].matching
          )
            this.addLineItemToSpace(drawerIndex, columnIndex, spaceIndex, false, lineItemId);

          this.clearAllMatching();

          this.drawers[drawerIndex - 1].columns[columnIndex - 1].spaces[
            spaceIndex - 1
          ].matching = true;
          updateDrawerData(this.$store.state, 'submit')
          this.lastSpace = `${this.letterFromNumber(columnIndex)}${spaceIndex}`;

          // foundSpace.style.transform = "scale(1.2)";
          // foundSpace.style.zIndex = 2;
        } else {
          // console.log("find next empty");
          const { drawerIndex, columnIndex, spaceIndex } = this.findNextEmpty();

          this.clearAllMatching();
          if (drawerIndex && columnIndex && spaceIndex) {
            this.markAsMatching({
              id: orderId,
              totalItems: parseInt(numberOfLineItems),
              drawerIndex,
              columnIndex,
              spaceIndex,
            });
            // space.id = matches[1];
            // space.totalItems = parseInt(matches[2]);
            // space.items = 0;
            // space.matching = true;
            // space.startedDate = this.$dayjs().format("YYYY-MM-DD HH:mm:ss");
            // space.modifiedDate = this.$dayjs().format("YYYY-MM-DD HH:mm:ss");
            createFlash("green");

            setTimeout(() => {
              const ele = document.querySelector(
                `[data-order-id="${orderId}"]`
              );
              if (ele && this.doScroll)
                ele.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
            }, 400);
          } else {
            // playError();
            // this.$store.commit(
            //   "changeErrorText",
            //   "Δεν βρέθηκε άδειο συρτάρι, δημιουργήστε ένα!"
            // );
            // createFlash("red");
            // this.toggleErrorModalOpen();
            this.$store.dispatch("addToRandomStackItemsMatching", {
              drawerIndex: 1,
              id: orderId,
              totalItems: parseInt(matches[2]),
            });
          }
        }
      } else {
        // console.log(val);
        if (val.includes("\\")) this.abortAny();
        else if (val.includes("/")) this.completeAny();
        else if (val.includes("clear")) {
          const clearMatches = val.match(/clear-([A-Z])([0-9]*)/);
          if (clearMatches && clearMatches.length > 1) {
            // console.log(clearMatches[1], clearMatches[2]);
            this.emptySpace(
              1,
              numberForLetter(clearMatches[1]),
              clearMatches[2]
            );
          }
        } else if (val.includes("undo")) {
          this.undo();
        } else {
          playError();
          setTimeout(() => {
            this.$store.commit(
              "changeErrorText",
              "Μη έγκυρο barcode, πρεπει να ειναι της μορφης 23423423-3"
            );
            createFlash("red");
            this.toggleErrorModalOpen();
          }, 300);
        }
      }
    },
    findNextEmpty() {
      const matches = this.$store.state.selectedSpace?.match(
        /([0-9]+)-([0-9]+)-([0-9]+)/
      );
      if (matches && matches.length === 4) {
        const drawerIndex = parseInt(matches[1]);
        const columnIndex = parseInt(matches[2]);
        const spaceIndex = parseInt(matches[3]);

        const space =
          this.drawers[drawerIndex - 1]?.columns[columnIndex - 1]?.spaces[
          spaceIndex - 1
          ];

        const ele = document.querySelector(
          `[data-testid="space-${drawerIndex}-${columnIndex}-${spaceIndex}"]`
        );

        if (ele && this.doScroll)
          ele.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });

        if (space && !space.id)
          return {
            drawerIndex,
            columnIndex,
            spaceIndex,
          };
      }

      // had to use for because forEach has random iteration order
      for (let i = 0; i < this.drawers.length; i++) {
        const drawer = this.drawers[i];
        for (let j = 0; j < drawer.columns.length; j++) {
          const column = drawer.columns[j];

          for (let k = 0; k < column.spaces.length; k++) {
            const space = column.spaces[k];

            if (!space.id && !space.totalItems) {
              this.lastSpace = `${this.letterFromNumber(j + 1)}${k + 1}`;
              return {
                drawerIndex: i + 1,
                columnIndex: j + 1,
                spaceIndex: k + 1,
              };
            }
          }
        }
      }

      return {};
    },
    findSpaceByOrderId(orderId) {
      return document.querySelector(`[data-order-id="${orderId}"]`);
    },
    addLineItemToRandomStack(abort, id) {
      this.$store.dispatch("addLineItemToSpace", {
        abort,
        id,
      });
    },
    addLineItemToSpace(drawerIndex, columnIndex, spaceIndex, abort = false, lineItemId) {
      this.$store.dispatch("addLineItemToSpace", {
        drawerIndex,
        columnIndex,
        spaceIndex,
        abort,
        lineItemId
      });
    },
    setKeyListeners() {
      document.addEventListener("keydown", (e) => {
        switch (e.key) {
          case barcodePrefix:
            if (this.$refs?.input) {
              this.$refs.input.focus();
              e.preventDefault();
              this.$refs.input.value = "";
            }
            break;
          case barcodeSuffix: // it is Enter key now
            if (this.$refs?.input && e.target === this.$refs?.input) {
              this.submit(this.$refs.input.value);
              this.$refs.input.value = "";
              this.$refs.input.blur();
            }
            if (e.target !== this.$refs?.input) {
              this.completeAny();
            }
            break;
          case "Escape":
            this.abortAny();
            break;
          default:
            if (
              (e.code.includes("Digit") || e.code.includes("Numpad")) &&
              e.target !== this.$refs.input &&
              e.target !== this.$refs.username &&
              e.target !== this.$refs.password
            )
              this.$refs.input.focus();
            return;
        }
      });
    },
    findMatchingSpace() {
      let matching = { drawerIndex: null, columnIndex: null, spaceIndex: null };

      this.drawers.forEach((drawer) => {
        drawer.columns.forEach((column) => {
          column.spaces.forEach((space) => {
            if (space.matching) {
              matching = {
                drawerIndex: drawer.index,
                columnIndex: column.index,
                spaceIndex: space.index,
              };
            }
          });
        });
      });
      return matching;
    },
    clearAllMatching() {
      // this.lastSpace = "";
      this.$store.dispatch("clearAllMatching");
    },
    checkLocalStorage() {
      this.$store.dispatch("init");
    },
    completeAny() {
      const { drawerIndex, columnIndex, spaceIndex } = this.findMatchingSpace();
      this.$store.dispatch("completeAny", {
        drawerIndex,
        columnIndex,
        spaceIndex,
      });
    },
    abortAny() {
      const { drawerIndex, columnIndex, spaceIndex } = this.findMatchingSpace();
      this.$store.dispatch("abortAny", {
        drawerIndex,
        columnIndex,
        spaceIndex,
      });
    },
    dayJSDate(date) {
      return this.$dayjs(date).format("DD/MM/YYYY");
    },
    itIsTodaysDate() {
      if (!this.stats.date) return false;

      if (this.$dayjs().format("YYYY-MM-DD") === this.stats.date) return true;
      else return false;
    },
  },
  mounted() {
    this.setKeyListeners();
    this.checkLocalStorage();

    if (!this.$store.loggedIn) {
      this.toggleLoginModalOpen(true);
    }

    this.check();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

#app {
  font-family: "Noto Sans KR", sans-serif, Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
