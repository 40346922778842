import success from "@/assets/sounds/success.wav";
import error from "@/assets/sounds/error.wav";
import completed from "@/assets/sounds/completed.wav";
/* eslint-disable */
export const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AK",
  "AL",
  "AM",
  "AN",
  "AO",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AX",
  "AY",
  "AZ",
  "BA",
  "BB",
  "BC",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BK",
  "BL",
  "BM",
  "BN",
  "BO",
  "BP",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BU",
  "BV",
  "BX",
  "BY",
  "BZ",
  "CA",
  "CB",
  "CC",
  "CD",
  "CE",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CP",
  "CQ",
  "CR",
  "CS",
  "CT",
  "CU",
  "CV",
  "CX",
  "CY",
  "CZ",
  "DA",
  "DB",
  "DC",
  "DD",
  "DE",
  "DF",
  "DG",
  "DH",
  "DI",
  "DK",
  "DL",
  "DM",
  "DN",
  "DO",
  "DP",
  "DQ",
  "DR",
  "DS",
  "DT",
  "DU",
  "DV",
  "DX",
  "DY",
  "DZ",
  "EA",
  "EB",
  "EC",
  "ED",
  "EE",
  "EF",
  "EG",
  "EH",
  "EI",
  "EK",
  "EL",
  "EM",
  "EN",
  "EO",
  "EP",
  "EQ",
  "ER",
  "ES",
  "ET",
  "EU",
  "EV",
  "EX",
  "EY",
  "EZ",
  "FA",
  "FB",
  "FC",
  "FD",
  "FE",
  "FF",
  "FG",
  "FH",
  "FI",
  "FK",
  "FL",
  "FM",
  "FN",
  "FO",
  "FP",
  "FQ",
  "FR",
  "FS",
  "FT",
  "FU",
  "FV",
  "FX",
  "FY",
  "FZ",
  "GA",
  "GB",
  "GC",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GK",
  "GL",
  "GM",
  "GN",
  "GO",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GV",
  "GX",
  "GY",
  "GZ",
];
/* eslint-enable */
export const numberForLetter = (letter) => {
  return letters.findIndex((oneLetter) => oneLetter === letter) + 1;
};

export const letterForNumber = (num) => {
  return letters[num - 1];
};

export const playSuccess = () => {
  var audio = new Audio(success);
  audio.play();
};

export const playError = () => {
  var audio = new Audio(error);
  audio.play();
};

export const playCompleted = () => {
  var audio = new Audio(completed);
  audio.play();
};

export const createFlash = (color) => {
  const greenFlash = document.createElement("div");
  greenFlash.style.background = color;
  greenFlash.style.position = "fixed";
  greenFlash.style.top = 0;
  greenFlash.style.right = 0;
  greenFlash.style.left = 0;
  greenFlash.style.bottom = 0;
  greenFlash.style.zIndex = 10;
  if (greenFlash) {
    document.body.appendChild(greenFlash);
    setTimeout(() => greenFlash.remove(), 300);
  }
};

export const alertTimeout = (msg, duration = 2000) => {
  setTimeout(() => {
    const ke = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      keyCode: 13,
    });
    document.body.dispatchEvent(ke);
  }, duration);
  alert(msg);
};

export const getStoragePrefix = () => {
  return `${
    window.location.hostname === "localhost" ? "local" : ""
  }${window.location.pathname.replace(/\/$/, "")}`;
};

export const getServerId = () => {
  if (window.location.hostname.includes("localhost")) return 3;
  else if (window.location.pathname.includes("canvas")) return 2;
  else return 1;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getOrigin = () => {
  if (window?.location?.origin) return window.location.origin;
  else return "";
};
